import { getParentBySelector } from "../helper"

// Filters a section with multiple elements like brands to show only the ones which match the content we type into the according search input
export class SearchList {
  constructor(selector) {
    let elements = document.querySelectorAll(selector)

    for (let el of elements) {
      new SearchListElement(el)
    }
  }
}

export class SearchListElement {
  constructor(el) {
    this.element = el
    this.listClassName = this.element.dataset.list
    this.noResultsListClassName = `${this.listClassName}--no-results`

    this.list = document.querySelector(this.listClassName)

    if (this.list) {
      this.items = this.list.querySelectorAll(".homepage__brands-item a")

      if (this.items.length) {
        this.noResultsList = document.querySelector(this.noResultsListClassName)

        this.element.addEventListener("keyup", function () {
          this.filter(this.element.value.trim().toLowerCase())
        }.bind(this))
      }
    }
  }

  filter(word) {
    let length = this.items.length
    let hidden = 0

    if (word.length == 0) {
      for (let i = 0; i < length; i++) {
        getParentBySelector(this.items[i], ".homepage__brands-item").classList.remove("d-none")
      }
    } else {
      for (let i = 0; i < length; i++) {
        if (this.items[i].title.trim().toLowerCase().includes(word)) {
          getParentBySelector(this.items[i], ".homepage__brands-item").classList.remove("d-none")
        }
        else {
          getParentBySelector(this.items[i], ".homepage__brands-item").classList.add("d-none")
          hidden++
        }
      }
    }

    // If all items are hidden, show the empty view
    if (hidden === length && this.noResultsList) {
      this.noResultsList.classList.remove("d-none")
    } else {
      this.noResultsList.classList.add("d-none")
    }
  }
}
