import { HeightEqualizer } from "./classes/HeightEqualizer"
import { SearchList } from "./classes/SearchList"
import Rails from '@rails/ujs'

export function startRails() {
  Rails.start()
}

export function selectB2bCustomer() {
  $('body').on('change', '#b2b_customers_dropdown, #b2b_customers_dropdown_mobile', function (e) {
    window.location = $(this).find('option:selected').val()
  })
}

// Freezes the background, so the user can't interact with it.
export function freezeBackground() {
  window.freezeBackground = () => { document.querySelector("html").style.cssText += "overflow: hidden;" }

  window.freezeBackground()
}

// Unfreezes the backgroud so the user can interact with it again
export function unfreezeBackground() {
  window.unfreezeBackground = () => { document.querySelector("html").style.cssText += "overflow: auto;" }

  window.unfreezeBackground()
}

export function initHeightEqualizer() {
  const tabs = document.querySelectorAll("button[data-bs-toggle='tab']")

  window.equalizeHeight = function () {

    setTimeout(() => {
      new HeightEqualizer()
    }, 500)
  }

  window.equalizeHeight()

  for (let tab of tabs) {
    tab.addEventListener("shown.bs.tab", function () {
      new HeightEqualizer(`.equal-height${tab.getAttribute("data-bs-target")}`)
    }, { once: true })
  }
}

export function initScrollTop() {
  const scrollUp = document.querySelector('.footer__scroll-top')

  if (scrollUp) {
    scrollUp.addEventListener('click', (e) => {
      e.preventDefault()

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    })
  }
}

// Enables the SearchList functionality for the required sections (filters, brands)
export function initSearchList() {
  new SearchList(".homepage__brands input")
}

export function initFiltersToggle() {
  document.querySelectorAll('.filter__category').forEach(function(category) {
    category.addEventListener('click', function() {
      let filter = this.closest('.filter')
      filter.classList.toggle('close-filter')
    })
  })
}
