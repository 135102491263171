import Swiper, { Autoplay, Navigation, Pagination, Thumbs } from "swiper"

Swiper.use([Autoplay, Navigation, Pagination, Thumbs])

export function initHomepageSlider() {
  const sliderSection = document.querySelector(".homepage__slider")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper"), {
      slidesPerView: 1,
      watchOverflow: true,
      speed: 500,
      autoplay: {
        delay: 3000
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    })
  }
}

export function initShopByCategorySlider() {
  const sliderSection = document.querySelector(".homepage__shop-by-category")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      speed: 500,
      spaceBetween: 5,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
      },
      watchOverflow: true,
      pagination: {
        el: '.homepage__shop-by-category .homepage-pagination',
        type: 'fraction'
      },
      navigation: {
        nextEl: sliderSection.querySelector('.btn-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-pagination--left')
      }
    })
  }
}

export function initGalaxySafetySlider() {
  const sliderSection = document.querySelector(".homepage__galaxy-safety")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      },
      pagination: {
        el: sliderSection.querySelector(".swiper-pagination"),
        type: "bullets",
        clickable: true
      }
    })
  }
}

export function initPyramexSlider() {
  const sliderSection = document.querySelector(".homepage__pyramex")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 30
        }
      },
      pagination: {
        el: sliderSection.querySelector(".swiper-pagination"),
        type: "bullets",
        clickable: true
      }
    })
  }
}

export function initShopNewInTabSlider() {
  function initShopNewInSlider() {
    setTimeout(() => {
      const sliderSection = document.querySelector(".homepage__suggested-products")

      if (sliderSection) {
        let initial_tab = sliderSection.querySelector(".tab-pane.active")

        initHomepagePresents(initial_tab)

        sliderSection.querySelectorAll("button[data-bs-toggle='tab']").forEach(tab => {
          tab.addEventListener("shown.bs.tab", function () {
            let getId = tab.getAttribute("data-bs-target")
            let active_tab = sliderSection.querySelector(`.tab-pane${getId}`)

            initHomepagePresents(active_tab)
          }, { once: true })
        })
      }
    }, 300)
  }

  function initHomepagePresents(active_tab) {
    setTimeout(() => {
      const sliderSection = active_tab.querySelector(".swiper-container")

      if (sliderSection) {
        new Swiper(sliderSection, {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 15,
          watchOverflow: true,
          breakpoints: {
            1200: {
              slidesPerView: 4
            },
            576: {
              slidesPerView: 3,
              spaceBetween: 30
            }
          },
          pagination: {
            el: active_tab.querySelector(".swiper-pagination"),
            type: "bullets",
            clickable: true
          }
        })
      }
    })
  }

  initShopNewInSlider()
}

export function initBestSellersSlider() {
  const sliderSection = document.querySelector(".homepage__best-sellers")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 15,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initHomepageSafetyChoiceSlider() {
  const sliderSection = document.querySelector(".homepage__safety-choice")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      watchOverflow: true,
      navigation: {
        nextEl: sliderSection.querySelector('.btn-swiper-pagination--right'),
        prevEl: sliderSection.querySelector('.btn-swiper-pagination--left')
      },
      pagination: {
        el: '.homepage__safety-choice .swiper-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className + '">' + "0" + (index + 1) +
         '</div>';
        },
      }
    })
  }
}

export function initHomepageBlogSlider() {
  const sliderSection = document.querySelector(".homepage__articles")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1140: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 2,
        }
      },
      pagination: {
        el: '.homepage__articles .swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initMainProductSlider() {
  setTimeout(() => {
    const slider = document.querySelector(".product-page__main-photo")
    const thumbs = document.querySelector(".product-page__thumbs")

    if (slider.querySelectorAll(".swiper-slide").length > 0) {
      new Swiper(slider, {
        slidesPerView: 1,
        slidesPerGroup: 1,
        watchOverflow: true,
        speed: 500,
        preloadImages: false,
        navigation: {
          nextEl: document.querySelector('.product-page-main-photo-pagination .btn-pagination--right'),
          prevEl: document.querySelector('.product-page-main-photo-pagination .btn-pagination--left')
        },
        pagination: {
          el: '.product-page-main-photo-pagination .product-page-pagination',
          type: 'fraction',
        },
        thumbs: {
          swiper: {
            el: '.product-page__thumbs',
            slidesPerView: 5,
            spaceBetween: 5,
          }
        }
      })
    }
  }, 1000)
}

export function initRelatedProductsSlider() {
  const sliderSection = document.querySelector(".product-page__related")

  if (sliderSection) {
    new Swiper(sliderSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
        }
      },
      pagination: {
        el: '.product-page__related .swiper-related-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}

export function initArticleRelatedProductsSlider() {
  const relatedProductsSection = document.querySelector(".article-page__related-products")

  if (relatedProductsSection) {
    new Swiper(relatedProductsSection.querySelector(".swiper-container"), {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 15,
      watchOverflow: true,
      breakpoints: {
        1200: {
          slidesPerView: 4,
        },
        576: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      },
      pagination: {
        el: '.article-page__related-products .swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    })
  }
}