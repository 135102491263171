import { getParentBySelector } from "../helper"

// Triggers Quickbuy
export function triggerQuickBuy() {
  window.triggerQuickBuy = function () {
    const addToCartButtons = document.querySelectorAll(".product__actions--add-to-cart")

    for (let addToCartButton of addToCartButtons) {
      addToCartButton.addEventListener("click", function (e) {
        e.preventDefault()

        setTimeout(() => {

          const product = getParentBySelector(this, ".product")
          const quickBuy = product.querySelector(".quick-buy")

          quickBuy.querySelector(".icon-close").addEventListener("click", function (e) {
            e.preventDefault()

            quickBuy.classList.remove("quick-buy--active")
          })

          quickBuy.classList.add("quick-buy--active")
        }, 300)
      })
    }
  }

  window.triggerQuickBuy()
}
